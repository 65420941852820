<template>
  <v-card v-if="commentLocal" outlined class="mb-2" color="secondary" dark>
    <div>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{
            commentLocal.author ? commentLocal.author.name : "Usuario eliminado"
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            fullDate(commentLocal.date)
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="isRole(0) || isRole(1)">
          <v-menu left>
            <template #activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="showUpdate = true">
                <v-list-item-icon class="mr-2">
                  <v-icon small>mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>Modificar comentario</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
    </div>
    <v-card-text style="white-space: pre-wrap">
      <span>{{ commentLocal.content }}</span>
      <v-img
        v-if="commentLocal.files.length"
        :src="commentLocal.files[0]"
        class="mt-3 comment-image"
        :max-width="
          showFullImage || !$vuetify.breakpoint.mdAndUp ? '100%' : '300px'
        "
        @click="viewImage(commentLocal.files[0])"
      ></v-img>

      <div v-if="commentLocal.customerCanView" class="mt-3">
        <v-chip color="secondary darken-2" small label
          >El cliente puede ver este comentario</v-chip
        >
      </div>
    </v-card-text>

    <update-comment
      v-if="showUpdate"
      :show="showUpdate"
      :currentComment="commentLocal"
      @cancel="showUpdate = false"
      @commentSaved="commentSaved"
    ></update-comment>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getFullDateWithHour } from "@/helpers/date.helper";

export default {
  name: "CommentCard",

  props: {
    comment: { type: Object, defualt: null },
    showFullImage: { type: Boolean, default: false },
  },

  components: {
    "update-comment": () => import("./commentDialog.vue"),
  },

  data() {
    return {
      commentLocal: this.comment,
      showUpdate: false,
    };
  },

  computed: {
    ...mapGetters(["isRole"]),
  },

  methods: {
    fullDate(isoDate) {
      return getFullDateWithHour(isoDate);
    },

    viewImage(imageUrl) {
      const a = document.createElement("a");
      a.href = imageUrl;
      a.target = "_blank";

      a.click();
      a.remove();
    },

    commentSaved(comment) {
      this.commentLocal = comment;
      this.showUpdate = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-image {
  cursor: pointer;
}
</style>
